
function Sidebar() {
  return (
    <header id="header">
	<div class="inner">
		<div class="picDiv">
		<a href="http://www.letsbuildwebsites.net" class="image avatar"><img src={'./images/mypic.jpg'} alt="Richard Berry" /></a>
		<h1><strong>I'm Richard</strong>, a Web Developer.</h1>
		</div>
	</div>
</header>
  )
}
export default Sidebar